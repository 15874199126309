.toggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 36px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}
.toggleButton:focus {
  outline: none;
}
.toggleButton .toggleButtonLine {
  width: 40px;
  height: 4px;
  background-color: black;
}
