.home-hero {
  padding-top: 100px;
}
@media (max-width: 768px) {
  .home-hero {
    padding-top: 20px;
  }
}

#container-header {
  padding: 100px;
}

@media (max-width: 768px) {
  #container-header {
    padding: 10px;
  }
}

.hero-image__header {
  width: 100%;
  height: auto;
}

.home-herotext__container {
  padding-top: 0px;
  padding-left: 100px;
}
@media (max-width: 768px) {
  .home-herotext__container {
    padding-left: 0px;
  }
}

.home-hero__content {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.home-hero__content h1 {
  font-size: 7em;
  position: relative;
  letter-spacing: 1px;
  margin: 0;
}
@media (max-width: 900px) {
  .home-hero__content h1 {
    font-size: 5em;
    position: relative;
    letter-spacing: 1px;
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

.extra-text__header {
  padding-top: 100px;
  font-size: 18px;
  line-height: 35px;
  color: #111;
  letter-spacing: 1px;
}
@media screen and (max-width: 900px) {
  .extra-text__header {
    padding-top: 100px;
    font-size: 14px;
    line-height: 35px;
    color: #111;
    letter-spacing: 1px;
  }
}
.grid-header__container {
  padding-top: 50px;
}

#Animated__btn {
  padding-top: 15px;
}

.Typewriter__wrapper {
  color: #481380;
}

.Typewriter__cursor {
  color: #481380;
}
