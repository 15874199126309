#expandable___card {
  margin: 0 auto;
  align-items: start;
  margin-top: 2em;
  margin-bottom: 3em;
  /* background: linear-gradient(to right, #2193b0, #6dd5ed); */
  max-width: 100%;
  color: rgb(0, 0, 0);
}

@media (max-width: 768px) {
  #expandable___card {
    margin: 0 auto;
    margin-top: 3em;
    max-width: 100%;
    margin-bottom: 5em;
  }
}

.expandable-card__img img {
  height: 300px;
  width: 300px;
  text-align: center;
}

.expandable-card__img {
  text-align: center;
}

.expandable-card__img h1 {
  text-align: center;
  font-size: 2.5em;
  font-weight: 500;
  letter-spacing: 2px;
}

.expandable-card__img p {
  font-size: 1.4em;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgb(8, 8, 8);
}

#expand-more__icon {
  font-size: 3em;
  color: rgb(0, 0, 0);
}

.Mobile-app-development__img h3 {
  font-size: 1.8em;
}

.Mobile-app-development__img p {
  font-size: 1.4em;
}

.icon-tab____collapse img {
  width: 30px;
  height: 30px;
}

.service-points ul {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .service-points ul {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    padding-left: 20px;
    margin-bottom: 0;
  }
}

.service-points {
  margin: 40px 0;
}

.service-points ul li {
  width: 50%;
  font-size: 1.4em;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .service-points ul li {
    width: 100%;
    font-size: 1.4em;
    font-weight: 500;
  }
}
