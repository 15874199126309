.page-about__qoute {
  background-color: #e6e6e6;
  padding: 3em;
  margin-top: 10em;
}

.page-about__qoute h1 {
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
}

.Header_______qoute-us {
  text-align: center;
  font-size: 3em;
  color: purple;
  padding-top: 3em;
}

.box-button-under__team {
  padding-bottom: 5em;
}
