#section-about__us {
  padding-top: 30em;
}

@media (max-width: 768px) {
  #section-about__us {
    padding-top: 10em;
  }
}

.section-about__header h1 {
  text-align: center;
  font-size: 4em !important;
  font-weight: 500;
  text-align: right;
  max-width: 80%;
}

.section-about__header p {
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 2em;
  max-width: 80%;
}
@media screen and (max-width: 768px) {
  .section-about__header p {
    text-align: center;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 2em;
    max-width: 100%;
  }
}
.section-about__image img {
  align-self: bottom;
}

.Box-btn {
  max-width: 80%;
}

@media screen and (max-width: 768px) {
  .Box-btn {
    max-width: 100%;
  }
}
