@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 10px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #212121;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden !important;
}
*::after ::before {
  box-sizing: border-box;
}

body.no-scroll {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

* ::before ::after {
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 768px) {
  .home_container {
    width: auto;
  }
}

.home-hero {
  padding-top: 100px;
}
@media (max-width: 768px) {
  .home-hero {
    padding-top: 20px;
  }
}

#container-header {
  padding: 100px;
}

@media (max-width: 768px) {
  #container-header {
    padding: 10px;
  }
}

.hero-image__header {
  width: 100%;
  height: auto;
}

.home-herotext__container {
  padding-top: 0px;
  padding-left: 100px;
}
@media (max-width: 768px) {
  .home-herotext__container {
    padding-left: 0px;
  }
}

.home-hero__content {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.home-hero__content h1 {
  font-size: 7em;
  position: relative;
  letter-spacing: 1px;
  margin: 0;
}
@media (max-width: 900px) {
  .home-hero__content h1 {
    font-size: 5em;
    position: relative;
    letter-spacing: 1px;
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

.extra-text__header {
  padding-top: 100px;
  font-size: 18px;
  line-height: 35px;
  color: #111;
  letter-spacing: 1px;
}
@media screen and (max-width: 900px) {
  .extra-text__header {
    padding-top: 100px;
    font-size: 14px;
    line-height: 35px;
    color: #111;
    letter-spacing: 1px;
  }
}
.grid-header__container {
  padding-top: 50px;
}

#Animated__btn {
  padding-top: 15px;
}

.Typewriter__wrapper {
  color: #481380;
}

.Typewriter__cursor {
  color: #481380;
}

:root {
  --background-color: #252a34;
  --hover-bg-color: #790c5a;
  --hover-txt-color: white;
  --distance: 4px;
  --border-color: #790c5a;
  --text-color: #e6739f;
  --animation-duration: 2s;
  --animation-ease: ease-in-out;
}

.animated-btn {
  padding: 6px;
  position: relative;
  border: 0;
  background-color: transparent;
  padding: 8px 24px;
  text-transform: uppercase;
  font-family: inherit;
  letter-spacing: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #e6739f;
  color: var(--text-color);
  overflow: hidden;
  margin: 16px 4px;
  cursor: pointer;
}
.animated-btn:hover {
  background-color: #790c5a;
  background-color: var(--hover-bg-color);
  color: white;
  color: var(--hover-txt-color);
}
.animated-btn::before,
.animated-btn::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: inline-flex;
  border: 2px solid #790c5a;
  border: 2px solid var(--border-color);
}
.animated-btn::after {
  -webkit-animation: animateAfter 2s ease-in-out
    infinite;
          animation: animateAfter 2s ease-in-out
    infinite;
  -webkit-animation: animateAfter var(--animation-duration) var(--animation-ease)
    infinite;
          animation: animateAfter var(--animation-duration) var(--animation-ease)
    infinite;
}
.animated-btn::before {
  -webkit-animation: animateBefore 2s ease-in-out
    infinite;
          animation: animateBefore 2s ease-in-out
    infinite;
  -webkit-animation: animateBefore var(--animation-duration) var(--animation-ease)
    infinite;
          animation: animateBefore var(--animation-duration) var(--animation-ease)
    infinite;
}
.animated-btn.reverse::before,
.animated-btn.reverse::after {
  animation-direction: reverse;
}
@-webkit-keyframes animateAfter {
  0% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translateX(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translateX(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translateX(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translateX(calc(-100% + var(--distance)));
  }
  25% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  50% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  75% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  100% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
}
@keyframes animateAfter {
  0% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translateX(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translateX(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translateX(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translateX(calc(-100% + var(--distance)));
  }
  25% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  50% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  75% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  100% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
}
@-webkit-keyframes animateBefore {
  0% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  25% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  35% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  75% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  100% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
}
@keyframes animateBefore {
  0% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  25% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  35% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  75% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  100% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
}


.toggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 36px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}
.toggleButton:focus {
  outline: none;
}
.toggleButton .toggleButtonLine {
  width: 40px;
  height: 4px;
  background-color: black;
}

.Navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 80px;
  z-index: 9999;
}

@media screen and (max-width: 768px) {
  .Navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    height: 60px;
    z-index: 9999;
  }
}

.NavbarNavigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}
.NavbarNavigation .spacer {
  flex: 1 1;
}
.NavbarNavigation .NavbarNavigationsItems ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.NavbarNavigation .NavbarNavigationsItems ul li {
  padding: 0 2rem;
  position: relative;
}

.NavbarNavigation .NavbarNavigationsItems ul li ul {
  position: absolute;
  left: 0;
  background: white;
  display: none;
}

.NavbarNavigation .NavbarNavigationsItems ul li:hover ul {
  display: block;
}

.NavbarNavigation .NavbarNavigationsItems ul li ul li {
  display: block;
  width: 200px;
}

.dropdown-content li {
  line-height: 5em;
}

.NavbarNavigation .NavbarNavigationsItems ul li a {
  color: black;
  text-decoration: none;
  font-size: 1.8rem;
}
.NavbarNavigation .NavbarNavigationsItems ul li a:hover,
.NavbarNavigation .NavbarNavigationsItems ul li a:active {
  color: #fa923f;
}
.NavbarNavigation .NavbarLogo {
  margin-left: 3rem;
}

.NavbarLogo a img {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 768px) {
  .NavbarLogo a img {
    width: 40px;
    height: 40px;
  }
}

.NavbarNavigation .NavbarLogo a {
  color: black;
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.5px;
}
@media (max-width: 768px) {
  .NavbarNavigationsItems {
    display: none;
  }
}
@media (min-width: 769px) {
  .NavbarToggleButton {
    display: none;
  }
  .Navbar .NavbarLogo {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .NavbarNavigation .NavbarLogo a {
    font-size: 1.5rem;
    background-color: white;
  }
}

.SideDrawer {
  height: 100%;
  background-color: white;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 60%;
  max-width: 400px;
  z-index: 200;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.SideDrawer.open {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
}
.SideDrawer ul {
  list-style: none;
  height: 100%;
  justify-content: top;
  padding-top: 100px;

  display: flex;
  flex-direction: column;
}
.SideDrawer ul li {
  margin: 2.5rem 0;
}
.SideDrawer ul li a {
  color: #521751;
  text-decoration: none;
  font-size: 2.2rem;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.SideDrawer ul li a:hover,
.SideDrawer ul li a:active {
  color: #fa923f;
}
@media (min-width: 769px) {
  .SideDrawer {
    display: none;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  top: 0;
  left: 0;
}

.section-services__header {
  text-align: center;
  font-size: 5em;
}

@media (max-width: 768px) {
  .section-services__header {
    font-size: 2.5em;
  }
}

#section-services__card {
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 768px) {
  #section-services__card {
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}

.btn-btn-all-services {
  text-decoration: none;
}

.img-services_home img {
  width: 200px;
  height: 200px;
  top: 0;
  position: relative;
  z-index: 10;
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 768px) {
  .img-services_home img {
    width: 120px;
    height: 120px;
    top: 0;
    position: relative;
    z-index: 10;
    transition: all 0.2s ease-in-out;
  }
}

.img-services_home img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.title-services__details h4 {
  display: block;
  font-size: 1.8em;
  margin-top: 20px;
  color: #38373b;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .title-services__details h4 {
    display: block;
    font-size: 1.6em;
    font-weight: normal;
    margin-top: 20px;
    color: #38373b;
    text-align: center;
  }
}

.animate__1 {
  text-decoration: none;
  padding-right: 40px;
}

.animate__1:hover {
  color: #e0b8ff;
}

.about-us__home {
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .about-us__home {
    padding-top: 0px;
    width: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .About-hero__content {
    text-align: center;
  }
}

.about-section__bg {
  height: 60vh;
  width: 100%;
  position: relative;
}

@media (max-width: 768px) {
  .about-section__bg {
    height: 300px;
    width: 100%;
    position: relative;
  }
}

.About-hero__content h1 {
  font-size: 7em;
  color: black;
  font-weight: 500;
  position: relative;
  letter-spacing: 1px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .About-hero__content h1 {
    font-size: 3em;
    text-align: center;
  }
}
@media only screen and (min-width: 800px) and (orientation: landscape) {
  .About-hero__content h1 {
    font-size: 3em;
    text-align: center;
  }
}

.About-hero__content span {
  padding-top: 100px;
  font-size: 14px;
  line-height: 35px;
  color: #252b33;
  letter-spacing: 1px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .About-hero__content span {
    padding-top: 0px;
    font-size: 12px;
    line-height: 35px;
    color: #252b33;
    letter-spacing: 1px;
  }
}

.About-hero__content h4 {
  padding-top: 50px;
  font-size: 4em;
}

.section-development h1 {
  text-align: center;
  font-size: 5em;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .section-development h1 {
    text-align: center;
    font-size: 3em;
    font-weight: normal;
  }
}

.links-to {
  text-decoration: none;
}

.Card-image__dev {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.Card-image__dev img {
  height: 80px;
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .Card-image__dev img {
    height: 60px;
    width: 60px;
  }
}

.Card-content__dev h4 {
  text-align: center;
  font-size: 3em;
}
@media screen and (max-width: 768px) {
  .Card-content__dev h4 {
    text-align: center;
    font-size: 2em;
  }
}

.Card-content__dev p {
  text-align: center;
  font-size: 1.5em;
}

@media screen and (max-width: 768px) {
  .Card-content__dev p {
    text-align: center;
    font-size: 1.4em;
  }
}

#card-develop__card:hover {
  box-shadow: -4px -4px 15px rgba(255, 255, 255, 0.1),
    4px 4px 15px rgba(0, 0, 0, 0.1);
}

#qoute-form {
  width: 80%;
  margin: auto;
  text-align: left;
  font-size: 14px;
  margin-top: 10px !important;
  padding-top: 20px;
}

#qoute-form label {
  color: #222;
  font-weight: 700;
  padding-left: 10px;
}

#qoute-form input {
  height: 25px;
  width: calc(100% - 10px);
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  margin: 6px 0 20px 0;
  padding-left: 10px;
  outline: none;
}

#qoute-form select {
  height: 25px;
  width: 100%;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  margin: 6px 0 20px 0;
  padding-left: 10px;
  outline: none;
}

#qoute-form textarea {
  height: 50px;
  width: calc(100% - 10px);
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  margin: 6px 0 20px 0;
  padding-left: 10px;
  outline: none;
  max-width: 100%;
}

#qoute-form input::-webkit-input-placeholder {
  color: #b4b4b4;
}

#qoute-form input:-ms-input-placeholder {
  color: #b4b4b4;
}

#qoute-form input::-ms-input-placeholder {
  color: #b4b4b4;
}

#qoute-form input::placeholder {
  color: #b4b4b4;
}

#qoute-form button {
  text-transform: uppercase;
  outline: 0;
  background: #4caf50;
  width: 30%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-bottom: 20px;
}

#qoute-form button:hover {
  background-image: linear-gradient(45deg, #ff0844 0%, #ffb199 100%);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 800px) {
  #signup-box {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  #signup-box {
    max-width: none;
    width: 100%;
    border-radius: unset;
    box-shadow: none;
  }
}

#simple-modal-title {
  text-align: center;
  font-size: 1.8em;
}

#modal-body-qoute__form {
  max-width: 40%;
  width: 40%;
  position: absolute;
  margin: 0 auto;
  background-color: white;
  border: none;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  #modal-body-qoute__form {
    max-width: 80%;
    width: 90%;
    max-height: auto;
    position: absolute;
    margin: 0 auto;
    background-color: white;
    border: none;
    border-radius: 10px;
  }
}

:root {
  --background-color: #252a34;
  --hover-bg-color: #790c5a;
  --hover-txt-color: white;
  --distance: 4px;
  --border-color: #790c5a;
  --text-color: #e6739f;
  --animation-duration: 2s;
  --animation-ease: ease-in-out;
}

.modal-btn {
  padding: 6px;
  position: relative;
  border: 0;
  background-color: transparent;
  padding: 8px 24px;
  text-transform: uppercase;
  font-family: inherit;
  letter-spacing: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #e6739f;
  color: var(--text-color);
  overflow: hidden;
  margin: 16px 4px;
  cursor: pointer;
}
.modal-btn:hover {
  background-color: #790c5a;
  background-color: var(--hover-bg-color);
  color: white;
  color: var(--hover-txt-color);
}
.modal-btn::before,
.modal-btn::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: inline-flex;
  border: 2px solid #790c5a;
  border: 2px solid var(--border-color);
}
.modal-btn::after {
  -webkit-animation: animateAfter 2s ease-in-out
    infinite;
          animation: animateAfter 2s ease-in-out
    infinite;
  -webkit-animation: animateAfter var(--animation-duration) var(--animation-ease)
    infinite;
          animation: animateAfter var(--animation-duration) var(--animation-ease)
    infinite;
}
.modal-btn::before {
  -webkit-animation: animateBefore 2s ease-in-out
    infinite;
          animation: animateBefore 2s ease-in-out
    infinite;
  -webkit-animation: animateBefore var(--animation-duration) var(--animation-ease)
    infinite;
          animation: animateBefore var(--animation-duration) var(--animation-ease)
    infinite;
}
.modal-btn.reverse::before,
.modal-btn.reverse::after {
  animation-direction: reverse;
}
@-webkit-keyframes animateAfter {
  0% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translateX(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translateX(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translateX(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translateX(calc(-100% + var(--distance)));
  }
  25% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  50% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  75% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  100% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
}
@keyframes animateAfter {
  0% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translateX(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translateX(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translateX(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translateX(calc(-100% + var(--distance)));
  }
  25% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  50% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  75% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  100% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
}
@-webkit-keyframes animateBefore {
  0% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  25% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  35% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  75% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  100% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
}
@keyframes animateBefore {
  0% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  25% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  35% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(-100% + 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(-100% + var(--distance)));
  }
  75% {
    -webkit-transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(-100% + 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(-100% + var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
  100% {
    -webkit-transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
            transform: translatey(calc(100% - 4px))
      translatex(calc(100% - 4px));
    -webkit-transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
            transform: translatey(calc(100% - var(--distance)))
      translatex(calc(100% - var(--distance)));
  }
}

.section-tablist {
  background-color: white;
  padding-top: 50px;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .section-tablist {
    background-color: white;
    padding-top: 0px;
  }
}

#tablist-container__section h1 {
  text-align: center;
  font-size: 5em;
  font-weight: 500;
  color: black;
}

.icon-tab__img img {
  width: 60px;
  height: 60px;
  color: black;
}

@media (max-width: 768px) {
  .icon-tab__img img {
    width: 30px;
    height: 30px;
    color: black;
  }
}

.icon-tab__img p {
  text-align: center;
  font-size: 12px;
  text-transform: lowercase;
}

.icon-tab__img {
  padding: 40px;
}

@media (max-width: 768px) {
  .icon-tab__img {
    padding: 12px;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .MuiTabs-flexContainer {
    justify-content: center;
  }
}

.MuiTab-wrapper {
  font-size: 1.3em;
}

#review-container {
  padding-bottom: 150px;
  padding-top: 40px;
}

@media (max-width: 768px) {
  #review-container {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.section-review__header h1 {
  text-align: center;
  font-size: 5em;
  font-weight: 500;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (max-width: 769px) {
  .section-review__header h1 {
    text-align: center;
    font-size: 3em;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.section-review__header p {
  text-align: center;
  font-size: 1.6em;
  font-weight: normal;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .section-review__header p {
    text-align: center;
    font-size: 1.5em;
    font-weight: normal;
    padding-bottom: 30px;
  }
}

.MuiCardHeader-content span {
  font-size: 1.5em;
}

.MuiAvatar-root {
  width: 80px;
  height: 800px;
}

@media (max-width: 768px) {
  #review-card__card {
    padding: 30px;
  }
}

.avatar-icon__review {
  height: 50px;
  width: 50px;
  align-self: center;
  color: #efefef;
}

.avatar-circle {
  width: 50px;
  height: 50px;
}

.avatar-icon__container {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.card-content_main {
  text-align: center;
  font-size: 1.5em;
  color: #4c4c4c;
}

#review-card__card:hover {
  box-shadow: -4px -4px 15px rgba(255, 255, 255, 0.1),
    4px 4px 15px rgba(0, 0, 0, 0.1);
}

#footer-navigation__container {
  padding-top: 50px;
}

.footer-distributed {
  background: #f5f5f5;
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12); */
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer-center {
  padding-top: 40px;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 40%;
}

/* The company logo */

.footer-distributed h3 {
  color: #000000;
  font: normal 36px "Open Sans", cursive;
  margin: 0;
}

.footer-distributed h3 span {
  color: lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links {
  color: #000000;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;

  padding-bottom: 10px;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: rgb(36, 36, 36);
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #ffffff;
  color: #000000;
  font-size: 25px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
  padding-top: 20px;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #000000;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: lightseagreen;
  text-decoration: none;
}

.footer-center img {
  width: 20px;
  height: 20px;
  padding-right: 20px;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: rgb(0, 0, 0);
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 20%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #000000;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
  float: right;
}

@media screen and (max-width: 768px) {
  .footer-distributed .footer-icons {
    margin-top: 25px;
    float: none;
  }
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding-right: 10px;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 2px;

  font-size: 20px;
  color: #000000;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 0px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}

.footer_img__logo {
  height: 100px;
  width: 100px;
}

.footer-icons img {
  width: 30px;
  height: 30px;
}

.contact-container {
  padding-top: 100px;
  padding-bottom: 100px;

  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@media screen and (max-width: 768) {
  .contact-container {
    padding-top: 10px;
    padding-bottom: 10px;

    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
}

.contact-container__img {
  background: url(/static/media/kanchenjunga.9086e8e9.png);
  background-size: auto;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

@media (max-width: 1280px) {
  .contact-container__img {
    background: url(/static/media/kanchenjunga-720p.aef30fe7.png);
    background-size: auto;
    background-repeat: no-repeat;
    height: 80vh;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .contact-container__img {
    background: url(/static/media/kanchenjunga-900.49677861.png);
    background-size: auto;
    background-repeat: no-repeat;
    height: 80vh;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contact-container__img {
    background: url(/static/media/kanchenjunga-600.2da93a13.png);
    background-size: auto;
    background-repeat: no-repeat;
    height: 50vh;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contact-container {
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
}

.contact-us-content {
  text-align: center;
}

.contact-us-content p {
  text-align: start;
  font-weight: 500;
  font-size: 1.4em;
  padding-left: 1px;
}

.contact-us-content h2 {
  text-align: start;
  font-weight: 500;
  font-size: 1.8em;
  padding-left: 1px;
}

@media screen and (max-width: 768px) {
  .contact-us-content h2 {
    text-align: center;
    font-weight: 500;
    font-size: 1.6em;
    padding-left: 1px;
    color: rgb(151, 148, 148);
  }
}

@media screen and (max-width: 768px) {
  .contact-us-content p {
    text-align: center;
    font-weight: 500;
    font-size: 1.2em;
    padding-left: 1px;
    color: rgb(151, 148, 148);
  }
}
.contact-us-content h1 {
  text-align: start;
  font-weight: 600;
  font-size: 3em;
  color: purple;
}

.contact-us-content h2 img {
  width: 14px;
  height: 14px;
  padding-right: 2px;
}

.contact-us-content p img {
  width: 14px;
  height: 14px;
  padding-right: 2px;
}

@media (max-width: 768px) {
  .contact-us-content h1 {
    text-align: center;
    font-weight: 600;
    font-size: 2.5em;
    color: purple;
  }
}

.contact-us-form h1 {
  text-align: center;
  font-weight: 400;
  font-size: 3em;
}

.contact-us-form p {
  text-align: center;
  font-weight: 400;
  font-size: 1.6em;
}

.form-contact {
  border-radius: 5px;
}

.form-contact h2 {
  text-align: center;
  margin: 0 0 20px;
  font-size: 3em;
}

.form {
  padding: 30px 40px;
}

.form-control {
  display: block;
  margin-bottom: 20px;
}

.form-control label {
  font-size: 2em;
  color: black;
}

.form-control input {
  border: 1px solid grey;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
}

.form-control textarea {
  border: 1px solid grey;
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 10vh;
  max-width: 100%;
  min-width: 100%;
  padding: 10px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .form-control textarea {
    border: 1px solid grey;
    border-radius: 4px;
    display: block;
    width: 100%;
    height: 20vh;
    max-width: 100%;
    min-width: 100%;
    padding: 10px;
    font-size: 14px;
  }
}

.form-control textarea input:focus {
  outline: 0;
  border-color: #777;
}

.form button {
  cursor: pointer;
  background-color: blue;
  border: 2px solid blue;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  margin-top: 20px;

  width: 20%;
}

.form-control p {
  color: green;
  font-size: 12px;
  text-align: center;
}

.paper-about-customer__approach img {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 768px) {
  .paper-about-customer__approach img {
    align-self: center;
  }
}

#section-about-us__2 h1 {
  text-align: center;
  font-size: 5em;
  font-weight: 500;
  padding-top: 2em;
  padding-bottom: 1em;
}

.paper-about-customer__approach h2 {
  font-size: 2.4em;
  font-weight: 600;
}

.paper-about-customer__approach p {
  font-size: 1.4em;
  max-width: 80%;
}

@media screen and (max-width: 768px) {
  .paper-about-customer__approach p {
    font-size: 1.2em;
    max-width: 100%;
  }
}

.paper-about-customer__approach {
  padding-left: 1em;
}

.page-about__qoute {
  background-color: #e6e6e6;
  padding: 3em;
  margin-top: 10em;
}

.page-about__qoute h1 {
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
}

.Header_______qoute-us {
  text-align: center;
  font-size: 3em;
  color: purple;
  padding-top: 3em;
}

.box-button-under__team {
  padding-bottom: 5em;
}

#section-about__us {
  padding-top: 30em;
}

@media (max-width: 768px) {
  #section-about__us {
    padding-top: 10em;
  }
}

.section-about__header h1 {
  text-align: center;
  font-size: 4em !important;
  font-weight: 500;
  text-align: right;
  max-width: 80%;
}

.section-about__header p {
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 2em;
  max-width: 80%;
}
@media screen and (max-width: 768px) {
  .section-about__header p {
    text-align: center;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 2em;
    max-width: 100%;
  }
}
.section-about__image img {
  align-self: bottom;
}

.Box-btn {
  max-width: 80%;
}

@media screen and (max-width: 768px) {
  .Box-btn {
    max-width: 100%;
  }
}

#section-about-us-meet__team {
  padding-top: 7em;
}

@media screen and (max-width: 768px) {
  #section-about-us-meet__team {
    padding-top: 1em;
  }
}

#section-about-us-meet__team h1 {
  font-size: 5em;
  text-align: center;
  font-weight: bold;
}

.section-paragraph-team {
  font-size: 2em;
  text-align: center;
  padding-bottom: 5em;
  font-weight: 400;
}

.section-about__us-image_icon {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.section-about__us-image_icon img {
  height: 200px;
  width: 200px;
}

.section-about__us-image_icon p {
  font-size: 14px;
  text-align: center;
}

.section-developer-we__Do {
}

#section1__services {
  padding-top: 25em;
}

@media screen and (max-width: 768px) {
  #section1__services {
    padding-top: 10em;
  }
}

.section1__services-img img {
  padding-top: 3em;
  background-size: 400px 250px;
}

.section1__services-header h1 {
  font-size: 5em;
  text-align: left;
  font-weight: 600;
}

.section1__services-header h3 {
  font-size: 1.6em;
  text-align: left;
  font-weight: 400;
}

#expandable-card__2 {
  background: linear-gradient(to right, #ad5389, #3c1053) !important;
}

.section2-services__header {
  text-align: center;
  font-size: 5em;
  font-weight: 600;
  padding-top: 3em;
}

@media (max-width: 768px) {
  .section2-services__header {
    text-align: center;
    font-size: 3em;
    font-weight: 600;
    padding-top: 1em;
  }
}

#expandable___card {
  margin: 0 auto;
  align-items: start;
  margin-top: 2em;
  margin-bottom: 1em;
  /* background: linear-gradient(to right, #2193b0, #6dd5ed); */
  max-width: 100%;
  color: rgb(0, 0, 0);
}

@media (max-width: 768px) {
  #expandable___card {
    margin: 0 auto;
    margin-top: 3em;
    max-width: 100%;
    margin-bottom: 5em;
  }
}

.expandable-card__img img {
  height: 300px;
  width: 300px;
  text-align: center;
}

.expandable-card__img {
  text-align: center;
}

.expandable-card__img h1 {
  text-align: center;
  font-size: 2.5em;
  font-weight: 500;
  letter-spacing: 2px;
}

.expandable-card__img p {
  font-size: 1.4em;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgb(8, 8, 8);
}

#expand-more__icon {
  font-size: 3em;
  color: rgb(0, 0, 0);
}

.Mobile-app-development__img h3 {
  font-size: 1.8em;
}

.Mobile-app-development__img p {
  font-size: 1.4em;
}

.icon-tab____collapse img {
  width: 30px;
  height: 30px;
}

.service-points ul {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .service-points ul {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    padding-left: 20px;
    margin-bottom: 0;
  }
}

.service-points {
  margin: 40px 0;
}

.service-points ul li {
  width: 50%;
  font-size: 1.4em;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .service-points ul li {
    width: 100%;
    font-size: 1.4em;
    font-weight: 500;
  }
}

.main-header__expandable {
  text-align: center;
  margin: 0 auto;
  font-size: 1.6em;
}

#expandable___card {
  margin: 0 auto;
  align-items: start;
  margin-top: 2em;
  margin-bottom: 3em;
  /* background: linear-gradient(to right, #2193b0, #6dd5ed); */
  max-width: 100%;
  color: rgb(0, 0, 0);
}

@media (max-width: 768px) {
  #expandable___card {
    margin: 0 auto;
    margin-top: 3em;
    max-width: 100%;
    margin-bottom: 5em;
  }
}

.expandable-card__img img {
  height: 300px;
  width: 300px;
  text-align: center;
}

.expandable-card__img {
  text-align: center;
}

.expandable-card__img h1 {
  text-align: center;
  font-size: 2.5em;
  font-weight: 500;
  letter-spacing: 2px;
}

.expandable-card__img p {
  font-size: 1.4em;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgb(8, 8, 8);
}

#expand-more__icon {
  font-size: 3em;
  color: rgb(0, 0, 0);
}

.Mobile-app-development__img h3 {
  font-size: 1.8em;
}

.Mobile-app-development__img p {
  font-size: 1.4em;
}

.icon-tab____collapse img {
  width: 30px;
  height: 30px;
}

.service-points ul {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .service-points ul {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    padding-left: 20px;
    margin-bottom: 0;
  }
}

.service-points {
  margin: 40px 0;
}

.service-points ul li {
  width: 50%;
  font-size: 1.4em;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .service-points ul li {
    width: 100%;
    font-size: 1.4em;
    font-weight: 500;
  }
}

.card__graphic__design {
  padding: 5px;
  align-items: center;
  justify-content: center;
}

.card__graphic__design h3 {
  text-align: center;
  font-size: 1.6em;
}

.card__graphic__design img {
  width: 15em;
  height: 15em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.row__second-graphic {
  padding-top: 2em;
}

.box-button-animated {
  padding-top: 5em;
}

.icon-tab____collapse-graphic img {
  width: 60px;
  height: 60px;
  padding-top: 3em;
}

@media screen and (max-width: 768px) {
  .icon-tab____collapse-graphic img {
    width: 30px;
    height: 30px;
    padding-top: 0em;
  }
}

.graphic-design_____header {
  padding-top: 3em;
}

@media screen and (max-width: 768px) {
  .graphic-design_____header {
    padding-top: 0em;
  }
}

