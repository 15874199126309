.Card-image__dev {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.Card-image__dev img {
  height: 80px;
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .Card-image__dev img {
    height: 60px;
    width: 60px;
  }
}

.Card-content__dev h4 {
  text-align: center;
  font-size: 3em;
}
@media screen and (max-width: 768px) {
  .Card-content__dev h4 {
    text-align: center;
    font-size: 2em;
  }
}

.Card-content__dev p {
  text-align: center;
  font-size: 1.5em;
}

@media screen and (max-width: 768px) {
  .Card-content__dev p {
    text-align: center;
    font-size: 1.4em;
  }
}

#card-develop__card:hover {
  box-shadow: -4px -4px 15px rgba(255, 255, 255, 0.1),
    4px 4px 15px rgba(0, 0, 0, 0.1);
}
