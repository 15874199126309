.section-tablist {
  background-color: white;
  padding-top: 50px;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .section-tablist {
    background-color: white;
    padding-top: 0px;
  }
}

#tablist-container__section h1 {
  text-align: center;
  font-size: 5em;
  font-weight: 500;
  color: black;
}
