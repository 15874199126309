.form-contact {
  border-radius: 5px;
}

.form-contact h2 {
  text-align: center;
  margin: 0 0 20px;
  font-size: 3em;
}

.form {
  padding: 30px 40px;
}

.form-control {
  display: block;
  margin-bottom: 20px;
}

.form-control label {
  font-size: 2em;
  color: black;
}

.form-control input {
  border: 1px solid grey;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
}

.form-control textarea {
  border: 1px solid grey;
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 10vh;
  max-width: 100%;
  min-width: 100%;
  padding: 10px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .form-control textarea {
    border: 1px solid grey;
    border-radius: 4px;
    display: block;
    width: 100%;
    height: 20vh;
    max-width: 100%;
    min-width: 100%;
    padding: 10px;
    font-size: 14px;
  }
}

.form-control textarea input:focus {
  outline: 0;
  border-color: #777;
}

.form button {
  cursor: pointer;
  background-color: blue;
  border: 2px solid blue;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  margin-top: 20px;

  width: 20%;
}

.form-control p {
  color: green;
  font-size: 12px;
  text-align: center;
}
