@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 10px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #212121;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden !important;
}
*::after ::before {
  box-sizing: border-box;
}

body.no-scroll {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

* ::before ::after {
  padding: 0px;
  margin: 0px;
}
