.SideDrawer {
  height: 100%;
  background-color: white;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 60%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}
.SideDrawer.open {
  transform: translateX(0) !important;
}
.SideDrawer ul {
  list-style: none;
  height: 100%;
  justify-content: top;
  padding-top: 100px;

  display: flex;
  flex-direction: column;
}
.SideDrawer ul li {
  margin: 2.5rem 0;
}
.SideDrawer ul li a {
  color: #521751;
  text-decoration: none;
  font-size: 2.2rem;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.SideDrawer ul li a:hover,
.SideDrawer ul li a:active {
  color: #fa923f;
}
@media (min-width: 769px) {
  .SideDrawer {
    display: none;
  }
}
