.paper-about-customer__approach img {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 768px) {
  .paper-about-customer__approach img {
    align-self: center;
  }
}

#section-about-us__2 h1 {
  text-align: center;
  font-size: 5em;
  font-weight: 500;
  padding-top: 2em;
  padding-bottom: 1em;
}

.paper-about-customer__approach h2 {
  font-size: 2.4em;
  font-weight: 600;
}

.paper-about-customer__approach p {
  font-size: 1.4em;
  max-width: 80%;
}

@media screen and (max-width: 768px) {
  .paper-about-customer__approach p {
    font-size: 1.2em;
    max-width: 100%;
  }
}

.paper-about-customer__approach {
  padding-left: 1em;
}
