.about-us__home {
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .about-us__home {
    padding-top: 0px;
    width: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .About-hero__content {
    text-align: center;
  }
}

.about-section__bg {
  height: 60vh;
  width: 100%;
  position: relative;
}

@media (max-width: 768px) {
  .about-section__bg {
    height: 300px;
    width: 100%;
    position: relative;
  }
}

.About-hero__content h1 {
  font-size: 7em;
  color: black;
  font-weight: 500;
  position: relative;
  letter-spacing: 1px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .About-hero__content h1 {
    font-size: 3em;
    text-align: center;
  }
}
@media only screen and (min-width: 800px) and (orientation: landscape) {
  .About-hero__content h1 {
    font-size: 3em;
    text-align: center;
  }
}

.About-hero__content span {
  padding-top: 100px;
  font-size: 14px;
  line-height: 35px;
  color: #252b33;
  letter-spacing: 1px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .About-hero__content span {
    padding-top: 0px;
    font-size: 12px;
    line-height: 35px;
    color: #252b33;
    letter-spacing: 1px;
  }
}

.About-hero__content h4 {
  padding-top: 50px;
  font-size: 4em;
}
